<template>
  <div class="auth-wrapper auth-v1 warpperContent">
    <v-card class="content">
      <v-card-text>
        <!-- Header -->
        <div class="text-center mb-12">
          <h2>
            ใบเสร็จรับเงิน (Receipt) <span class="text_numberBill">#{{ this.$store.state.numberBill }}</span>
          </h2>
        </div>
        <!-- End Header -->

        <!-- ข้อมูลร้านค้า และลูกค้า -->
        <v-row>
          <!-- Content Left -->
          <v-col
            md="6"
            sm="6"
            cols="12"
          >
            <h3 class="mb-2">
              ร้านค้าผู้ให้บริการ
            </h3>
            <p class="mb-1">
              SonZa Mock Data
            </p>
            <p class="mb-1">
              ดูสินค้าเพิ่มเติมได้ที่ son_Za.net
            </p>
            <p class="mb-1">
              ติดตามข่าวสารได้ที่ son_Za.net
            </p>
          </v-col>
          <!-- End Content Left -->

          <!-- Content Right -->
          <v-col
            md="6"
            sm="6"
            cols="12"
          >
            <h3 class="mb-2">
              รายละเอียดลูกค้า
            </h3>
            <p class="mb-1">
              {{ this.$store.state.nameCustomer }}
            </p>
            <p class="mb-1">
              วันที่เปิดบิล {{ this.$store.state.timePay }}
            </p>
          </v-col>
          <!-- End Content Right -->
        </v-row>
        <!-- สิ้นสุดข้อมูลร้านค้า และลูกค้า -->

        <!-- รายละเอียดสินค้า -->
        <hr class="mt-10 mb-3" />
        <v-row>
          <v-col
            md="6"
            sm="3"
            cols="3"
          >
            <h3>รายการสินค้า</h3>
          </v-col>
          <v-col
            md="2"
            sm="3"
            cols="3"
          >
            <h3 class="d-flex justify-end">
              จำนวน
            </h3>
          </v-col>
          <v-col
            md="2"
            sm="3"
            cols="3"
          >
            <h3 class="d-flex justify-end">
              ราคาต่อหน่วย
            </h3>
          </v-col>
          <v-col
            md="2"
            sm="3"
            cols="3"
          >
            <h3 class="d-flex justify-end">
              จำนวนเงิน
            </h3>
          </v-col>
        </v-row>
        <hr class="mt-3 mb-3" />
        <li
          v-for="item in this.$store.state.mockData"
          :key="item.id"
          class="mb-1"
        >
          <v-row>
            <v-col
              md="6"
              sm="3"
              cols="3"
            >
              <img
                :src="item.img"
                alt="test"
                class="mt-1"
              />
              <span class="mx-2">ชื่อสินค้า : {{ item.nameProduct }}</span>
            </v-col>
            <v-col
              md="2"
              sm="3"
              cols="3"
            >
              <span class="d-flex justify-end transform_text">{{ item.amount }}</span>
            </v-col>
            <v-col
              md="2"
              sm="3"
              cols="3"
            >
              <span class="d-flex justify-end transform_text">{{ item.price }} บาท</span>
            </v-col>
            <v-col
              md="2"
              sm="3"
              cols="3"
            >
              <span class="d-flex justify-end transform_text">{{ item.price }} บาท</span>
            </v-col>
          </v-row>
        </li>
        <hr class="mt-3 mb-1" />
        <hr class="mb-6" />
        <!-- สิ้นสุดรายละเอียดสินค้า -->

        <!-- หมายเหตุ และราคารวม -->
        <v-row>
          <v-col
            md="6"
            sm="6"
            cols="12"
          >
            <v-textarea
              v-model="this.$store.state.note"
              label="หมายเหตุ"
              hide-details
              auto-grow
              outlined
              rows="1"
              row-height="15"
            ></v-textarea>
          </v-col>
          <v-col
            md="6"
            sm="6"
            cols="12"
          >
            <div class="d-flex justify-space-between mb-2">
              <p class="mb-0">
                ทั้งหมด
              </p>
              <p class="mb-0">
                150 บาท
              </p>
            </div>
            <div class="d-flex justify-space-between mb-2">
              <p class="mb-0">
                ส่วนลด
              </p>
              <p class="mb-0">
                0 บาท
              </p>
            </div>
            <div class="d-flex justify-space-between mb-2">
              <p class="mb-0">
                ค่าจัดส่ง
              </p>
              <p class="mb-0">
                50 บาท
              </p>
            </div>
            <div class="d-flex justify-space-between mb-2 totalPrice">
              <p class="mb-0">
                รวมราคาสุทธิ
              </p>
              <p class="mb-0">
                200 บาท
              </p>
            </div>
          </v-col>
        </v-row>
        <!-- สิ้นสุดหมายเหตุ และราคารวม -->
        <h4 class="text-center mt-10">
          ขอบคุณที่ใช้บริการ (Thank you)
        </h4>
        <hr class="mt-10 mb-3 lineBottom" />
        <v-row>
          <v-col
            md="6"
            sm="6"
            cols="12"
          >
            <h3>SonZa Demo Data</h3>
          </v-col>
          <v-col
            md="6"
            sm="6"
            cols="12"
            class="borderLeft"
          >
            <span class="text_numberBill">#{{ this.$store.state.numberBill }}</span>
            <p class="mb-1 mt-2 text_customer">
              {{ this.$store.state.nameCustomer }}
            </p>
          </v-col>
        </v-row>
        <hr class="mt-3 mb-3 lineBottom" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

hr {
  background-color: #999999;
  height: 2px;
  border: none;
}
hr.lineBottom {
  border: 1px dashed #999999;
  background-color: transparent;
}
li {
  list-style-type: decimal;
}
img {
  width: 2rem;
  height: 2rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 5px;
}
.warpperContent {
  padding: 2rem 2rem;
  display: flex;
  justify-content: center;
}
.content {
  width: 70%;
}
.text_numberBill {
  color: white;
  font-size: 24px;
  background-color: #0d6efd;
  padding: 5px 10px;
  border-radius: 5px;
}
.transform_text {
  transform: translateY(100%);
}
.totalPrice {
  background-color: #8a8d93;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}
.borderLeft {
  border-left: 1px dashed #999999;
}
.text_customer {
  font-size: 20px;
}

@media screen and (max-width: 600px) {
  .warpperContent {
    padding: 2rem 2rem;

  }
}
</style>
